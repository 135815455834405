.icon-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4em;
    height: 2.4em;
    background-color: var(--color-red);
    border-radius: 2em;

    &::after {
        display: inline-block;
        width: 1.5em;
        height: 1em;
        content: "";
        background: url('../images/icon-svg/arrow-right.svg') no-repeat center center;
        background-size: contain;
    }
    @each $name, $color in $colors {
        &._#{$name} {
            background-color: $color;
        }
    }
    @each $name, $color in $colors {
        &._white-#{$name} {
            background-color: #fff;

            &::after {
                background-image: url('../images/icon-svg/arrow-right-#{$name}.svg');
            }
        }
    }
}

@use '../functions/font' as font;

.dino-header {
    padding: 4rem 1rem 2rem;
    background-color: var(--color-blue-80);
    text-align: center;
    margin-bottom: 60px;
    @include mq {
        padding: 2rem 1rem 2rem;
    }
    &__sub-title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        color: #fff;
        font-size: font.clamp-fz(24, 32);
        margin-bottom: .5em;
        strong {
            display: inline-block;
            padding: .25em .5em;
            font-size: 70%;
            border: 1px solid #fff;
            line-height: 1em;
        }
        @include mq {
            flex-direction: column;
            gap: .5rem;
        }
    }
    &__title {
        display: block;
        width: fit-content;
        padding: 1em 2em;
        margin-right: auto;
        margin-left: auto;
        color: var(--color-blue);
        background: url('../images/title/bg-sub-title.svg') no-repeat center center;
        background-size: 100%;
        font-size: font.clamp-fz(24, 46);
        line-height: 1.5em;
        @include mq {
            padding: 1em 1em;
        }
    }
    &__intro {
        max-width: $width-contents-m;
        margin: 0 auto;
        filter: drop-shadow(0 0 4px rgb(0 0 0 / 20%));
        margin-bottom: -60px;
        &__inner {
            $offset: 20px;

            padding: 2rem;
            background-color: #fff;
            clip-path: polygon(calc(0% + $offset) 0, calc(100% - $offset) 0, 100% $offset, 100% calc(100% - $offset), calc(100% - $offset) 100%, calc(0% + $offset) 100%, 0 calc(100% - $offset), 0 $offset);
            text-align: left;

            @include mq {
                padding: 1em 1.5em;
            }
        }
    }
}
.dino-contents {

}

.wpra-dino-contents__slider__main {
    position: relative;
}
.dino-contents__slider__main__nav {
    position: absolute;
    top: 50%;
    border: none;
    background-color: var(--color-blue);
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    padding: 0;
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.swiper-button-disabled {
        opacity: 0.25;
        cursor: initial;
    }
    .icon {
        background-color: #fff;
    }
    &._prev {
        left: -3rem;
    }
    &._next {
        right: -3rem;
    }
}


.dino-contents__slider {
    box-sizing: border-box;
    max-width: calc($width-contents-m + 120px);
    margin: 0 auto;
    padding-left: 60px;
    padding-right: 60px;
    @include mq {
        overflow: hidden;
        padding-right: 0;
        padding-left: 0;
    }
    &__main {
        .swiper-slide {
            // width: 100%;
            aspect-ratio: 4 / 3;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }


    }
    &__thumb {
        box-sizing: border-box;
        margin-top: 1rem;
        &.swiper {
            padding-bottom: 1rem;
            @include mq {
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }
        .swiper-slide {
            box-sizing: border-box;
            .slide-inner {
                border: 2px solid transparent;
                aspect-ratio: 4 / 3;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .swiper-slide-thumb-active {
            .slide-inner {
                border-color: var(--color-blue);
            }

        }
    }
}

.dino-contents__map {
    @include mq {
        // padding-right: 1rem;
        // padding-left: 1rem;
    }
    &__title {
        width: fit-content;
        margin: 0 auto 0;
        color: var(--color-blue);
        font-size: font.fz(60);
        margin-bottom: -.5em;
        position: relative;
        z-index: 500;
        @include mq {
            font-size: font.fz(40);
        }
    }
    &__map {
        height: 60vh;
        background-color: #999;
        @include mq {
            height: 50vh;
        }
    }
    .wrap-btn {
        width: fit-content;
        margin: -1em auto 0;
        position: relative;
        z-index: 500;
        @include mq {
            margin-top: 1rem;
        }
    }
}

.dino-contents__guide {
    background-color: var(--color-blue-80);
    position: relative;
    margin-top: 120px;
    margin-bottom: 120px;
    padding: 3rem 2rem;
    position: relative;
    @include mq {
        margin-top: 80px;
        margin-bottom: 80px;
        padding: 1rem 1rem;
    }
    &::before,
    &::after {
        position: absolute;
        left: 0;
        z-index: 100;
        display: block;
        width: 100%;
        height: 90px;
        content: "";
        background-repeat: repeat-x;
        background-position: 0 center;
        background-size: auto 100%;
        @include mq {
            height: 40px;
        }
    }

    &::before {
        top: -90px;
        background-image: url('../images/patterns/blue-top.png');
        @include mq {
            top: -40px;
        }
    }

    &::after {
        bottom: -90px;
        background-image: url('../images/patterns/blue-bottom.png');
        @include mq {
            bottom: -40px;
        }
    }
    &__dino {
        position: absolute;
        top: 0;
        &._left {
            left: 0;
        }
        &._right {
            right: 0;
        }
        @include mq(1000px) {
            display: none;
        }
    }
    &__title {
        margin: 1em 0 1em;
        text-align: center;
        font-size: font.clamp-fz(24, 40);
        color: #fff;
        strong {
            font-size: 1.5em;
            display: inline-block;
        }
    }
    &__contents {
        box-sizing: border-box;
        max-width: $width-contents-m;
        margin-right: auto;
        margin-left: auto;
        background-color: #fff;
        padding: 2rem;
        position: relative;
    }
}


.dino-list {
    max-width: $width-contents-m;
    margin: 0 auto;
    padding-right: 2rem;
    padding-left: 2rem;
    @include mq {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    &__title {
        margin: 10rem 0 4rem;
        text-align: center;
        font-size: font.clamp-fz(24, 40);
        strong {
            color: var(--color-blue);
            font-size: 1.5em;
        }
        @include mq {
            margin-top: 2rem;
            margin-bottom: 1.5rem;
        }
    }
    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        list-style: none;
        padding: 0 0 4rem;
        margin: 0;
        gap: 2rem;
        @include mq {
            grid-template-columns: 1fr;
        }
        li {
            padding: 2rem;
            background-color: #fff;
            filter: drop-shadow(0 0 4px rgb(0 0 0 / 20%));
            @include mq {
                padding: 1rem 1.5rem;
            }
        }
        &__title {
            padding: 0 0 .25em;
            font-size: font.fz(20);

            font-weight: bold;
            border-bottom: 1px solid var(--color-brown);
        }
        &__info {
            display: grid;
            grid-template-columns: 1fr 4fr;
            dt {
                grid-column: 1 / 2;
                border-bottom: 1px dashed var(--color-brown);
                padding: .5em 1em .5em 0;
                font-weight: bold;
            }
            dd {
                margin: 0;
                grid-column: 2 / 3;
                border-bottom: 1px dashed var(--color-brown);
                padding: .5em 0;
            }
            @include mq {
                grid-template-columns: 1fr;
                dt {
                    grid-column: 1 / 2;
                    border-bottom: none;
                    padding-bottom: 0;
                }
                dd {
                    grid-column: 1 / 2;
                    padding-top: .2em;
                }
            }
        }

    }
}

@use '../functions/font' as font;

.page-404 {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &__title {
        font-size: font.fz(40);
        text-align: center;
    }
}

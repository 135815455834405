@use 'sass:color';
@use '../functions/font' as font;
@mixin tinymce-fukui_dinosaur {
    .fukui_dinosaur__header {
        position: relative;
        padding: 2rem 1rem;
        margin-bottom: 120px;
        text-align: center;
        background-color: var(--color-red-80);
        // overflow: hidden;
        @include mq {
            margin-bottom: 40px;
        }
        &::after {
            position: absolute;
            bottom: -90px;
            left: 0;
            z-index: 100;
            display: block;
            width: 100%;
            height: 90px;
            content: "";
            background-image: url('../images/patterns/red-bottom.png');
            background-repeat: repeat-x;
            background-position: 0 center;
            background-size: auto 100%;
            @include mq {
                bottom: -27px;
                height: 28px;
    
            }
        }
    }


    .fukui_dinosaur__header__title {
        position: relative;
        width: fit-content;
        padding: 1em 2em;
        margin: 1.5em auto .5em;
        background: url('../images/title/bg-sub-title.svg') no-repeat center center;
        background-size: 100%;
        font-size: font.clamp-fz(20, 38);
        line-height: 1.75em;
        letter-spacing: 0.25em;
        strong {
            color: var(--color-red);
            font-size: 1.5em;
        }
        @include mq {
            padding: 1em 1em 3rem;
        }
    }
    .fukui_dinosaur__header__img {
        display: inline-block;
        position: absolute;
        &._left {
            top: 0;
            left: -150px;
        }
        &._right {
            top: 50px;
            right: -150px;
        }
    }

    .fukui_dinosaur__intro {
        margin-top: -120px;
        padding: 140px 2rem 20px;
        background-color: var(--color-red-20);
        &__text {
            max-width: $width-contents-s;
            margin: 0 auto;
            filter: drop-shadow(0 0 4px rgb(0 0 0 / 5%));
        }
        &__text__inner {
            background-color: #fff;
            padding: 2em;
            font-size: font.fz(17);
            line-height: 2em;
            text-align: center;

            $offset: 16px;
            clip-path: polygon(calc(0% + $offset) 0, calc(100% - $offset) 0, 100% $offset, 100% calc(100% - $offset), calc(100% - $offset) 100%, calc(0% + $offset) 100%, 0 calc(100% - $offset), 0 $offset);

            @include mq {
                padding: 1em 1.5em;
            }
        }
    }


    .fukui_dinosaur__contents__item {
        padding: 4rem 2rem;
        position: relative;
        &__inner {
            max-width: $width-contents-m;
            margin: 0 auto;
            position: relative;
        }
        .item-img {
            position: absolute;
            top: 0;
            @include mq {
                display: none;
            }
        }
        .item-title {
            margin: 0 0 3em;
            display: flex;
            align-items: center;
            flex-direction: column;
            font-weight: bold;
            gap: .5em;
            text-align: center;
            line-height: 2em;
            @include mq {
                margin-bottom: 1em;
            }
            .year {
                padding: .5em;
                // font-size: font.fz(24);
                font-size: font.clamp-fz(18, 24);
                border: 1px solid var(--color-font);
                line-height: 1em;
                strong {
                    font-size: 150%;
                }
            }
            .text {
                display: block;
                line-height: 1.5em;
                // font-size: font.fz(32);
                font-size: font.clamp-fz(24, 42);
            }
        }
        .item-contents {
            display: flex;
            gap: 2rem;
            &__wrap-img {
                width: calc((100% - 2rem) * 0.4);
            }
            &__img {
                width: 120%;
            }
            &__text {
                width: calc((100% - 2rem) * 0.6);
                line-height: 2rem;
            }
            @include mq {
                flex-direction: column;
                &__wrap-img {
                    width: 100%;
                    order: 1;
                }
                &__img {
                    width: 100%;
                }
                &__text {
                    order: 2;
                    width: 100%;
                }
            }
        }
    }
    .fukui_dinosaur__contents {
        .fukui_dinosaur__contents__item {
            &:nth-of-type(even) {
                background-color: var(--color-red-80);
                padding: 160px 2rem;
                color: #fff;
                @include mq {
                    padding: 40px 1rem;
                }
                &::before,
                &::after {
                    position: absolute;
                    left: 0;
                    z-index: 100;
                    display: block;
                    width: 100%;
                    height: 90px;
                    content: "";
                    background-repeat: repeat-x;
                    background-position: 0 center;
                    background-size: auto 100%;
                    @include mq {
                        height: 40px;
                    }
                }
            
                &::before {
                    top: -90px;
                    background-image: url('../images/patterns/red-top.png');
                    @include mq {
                        top: -40px;
                    }
                }
            
                &::after {
                    bottom: -90px;
                    background-image: url('../images/patterns/red-bottom.png');
                    @include mq {
                        bottom: -40px;
                    }
                }
                .item-img {
                    top: 60px;
                    left: 0;
                }
                .item-title {
                    .year {
                        border-color: #fff;
                    }
                    .text {
                        background-color: rgb(#ee7673, 90%);
                    }
                }
                .item-contents__text {
                    background-color: rgb(#ee7673, 90%);
                }
                .item-contents__img {
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 30px 100%, 0 calc(100% - 30px));
                    margin-right: -20%;
                    @include mq {
                        margin-right: 0;
                    }
                }
                .item-contents__img-desc {

                }
            }
            &:nth-of-type(odd) {
                background-color: var(--color-red-20);
                padding: 200px 2rem 180px;
                @include mq {
                    padding: 80px 1rem 70px;
                }
                .item-img {
                    top: 80px;
                    right: 0;
                }
                .item-title {
                    .text {
                        background-color: rgb(#fbdddc, 90%);
                    }
                }
                .item-contents__text {
                    background-color: rgb(#fbdddc, 90%);
                }
                .item-contents__img {
                    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%);
                    margin-left: -20%;
                    @include mq {
                        margin-left: 0;
                    }
                }
                .item-contents__img-desc {
                    text-align: right;
                }
            }
        }

    }

    .fukui_dinosaur__outro {
        padding: 20px 2rem 6rem;
        background-color: var(--color-red-20);
        position: relative;
        @include mq {
            padding: 0 1rem 4rem;
        }
        &::after {
            position: absolute;
            left: 0;
            z-index: 100;
            display: block;
            width: 100%;
            height: 90px;
            content: "";
            background-repeat: repeat-x;
            background-position: 0 center;
            background-size: auto 100%;
            bottom: -90px;
            background-image: url('../images/patterns/red-bottom-20.png');
            @include mq {
                height: 40px;
                bottom: -40px;
            }
        }
        &__text {
            max-width: $width-contents-s;
            margin: 0 auto;
            filter: drop-shadow(0 0 4px rgb(0 0 0 / 5%));
        }
        &__text__inner {
            background-color: #fff;
            padding: 2em;
            font-size: font.clamp-fz(16, 20);
            line-height: 2.25em;
            text-align: center;

            $offset: 16px;
            clip-path: polygon(calc(0% + $offset) 0, calc(100% - $offset) 0, 100% $offset, 100% calc(100% - $offset), calc(100% - $offset) 100%, calc(0% + $offset) 100%, 0 calc(100% - $offset), 0 $offset);
            @include mq {
                padding: 1em;
            }
        }
    }

    .fukui_dinosaur__kingdom {
        padding: 10rem 2rem 4rem;
        text-align: center;
        background: url('../images/patterns/bg-color-2.png') repeat 0 0;
        &__title {
            font-size: font.clamp-fz(26, 32);
            color: var(--color-blue);
        }
        &__text {
            line-height: 2em;
        }
        &__text_2 {
            line-height: 2em;
            font-size: font.clamp-fz(18, 24);
            color: var(--color-blue);
            font-weight: bold;
        }
        @include mq {
            padding: 4rem 1rem 2rem;
        }
    }

    .fukui_dinosaur__station-img {
        img {
            width: 100%;
        }
    }

    .fukui_dinosaur__column {
        padding: 6rem 1rem;
        @include mq {
            padding: 3rem 1rem;
        }
    }
    .fukui_dinosaur__column__inner {
        max-width: $width-contents-m;
        margin: 0 auto;
        padding: 4rem;
        background-color: var(--color-red-20);
        @include mq {
            padding: 4rem 2rem;
        }
        .column__sub-title {
            text-align: center;
            font-size: font.clamp-fz(38, 60);
            color: var(--color-red);
            font-weight: bold;
            margin: -1.75em 0 0;
            letter-spacing: 0.15em;
            line-height: 1em;
            @include mq {
                margin-top: -2.2em;
            }
        }
        .column__title {
            margin: 1em 0 2em;
            font-size: font.clamp-fz(24, 32);
            text-align: center;
        }
        .column__contents {
            &__text {
                margin: 0;
                // width: calc((100% - 2rem) * 0.6 );
            }
            &__img {
                width: calc((100% - 2rem) * 0.4 );
                float: right;
                margin-left: 1.5em;
                margin-bottom: 1.5em;
            }
            @include mq {
                // flex-direction: column-reverse;
                &__text {
                    width: 100%;
                }
                &__img {
                    width: 100%;
                    margin: 0 0 1.5em;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }


}

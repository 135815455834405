h1, h2, h3, h4, h5, h6 {
    line-height: 1.5em;
    letter-spacing: 0.05em;
}

.lang-en {
    h1, h2, h3, h4, h5, h6 {
        line-height: 1.25em;
        letter-spacing: 0.02em;
    }
}

p {
    line-height: 2em;
}

.lang-en {
    p {
        line-height: 1.5em;
    }
}

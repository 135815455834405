.pagination-wrapper {
    padding-right: 2rem;
    padding-left: 2rem;
}

.pagination {
    display: flex;
    gap: .5em;
    align-items: center;
    justify-content: center;
    padding: 0;
    list-style: none;
}

.pagination-item {
    $shift: 7px;
    $shift-inner: 6px;

    min-width: 2rem;
    padding: 2px;
    clip-path: polygon(calc(0% + $shift) 0, calc(100% - $shift) 0, 100% $shift, 100% calc(100% - $shift), calc(100% - $shift) 100%, calc(0% + $shift) 100%, 0 calc(100% - $shift), 0 $shift);
    background-color: var(--color-blue);

    .link {
        display: block;
        padding: .5em;
        clip-path: polygon(calc(0% + $shift-inner) 0, calc(100% - $shift-inner) 0, 100% $shift-inner, 100% calc(100% - $shift-inner), calc(100% - $shift-inner) 100%, calc(0% + $shift-inner) 100%, 0 calc(100% - $shift-inner), 0 $shift-inner);
        font-weight: bold;
        line-height: 1em;
        color: var(--color-blue);
        text-align: center;
        text-decoration: none;
        background-color: #fff;

        .icon {
            background-color: var(--color-blue);
        }
    }

    &.is-active {
        .link {
            color: #fff;
            background-color: var(--color-blue);

            .icon {
                background-color: #fff;
            }
        }
    }

    a.link {
        &:hover {
            color: #fff;
            background-color: var(--color-blue);

            .icon {
                background-color: #fff;
            }
        }
    }



    &._blank {
        background-color: #fff;
    }
}

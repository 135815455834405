
body {
    margin: 0;
}


input, textarea {
    font-size: 1rem;
}

input, textarea, button, select {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
}

input[type="text"], input[type="number"], input[type="password"], input[type="tel"], input[type="button"], input[type="submit"], textarea, button, select {
    box-sizing: border-box;
    appearance: none;
}

select {
    color: var(--color-font);
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

@use '../functions/font' as font;

.btn {
    display: inline-block;
    padding: 0;
    margin: 0;
    background: none;
    filter: drop-shadow(0 0 4px rgb(0 0 0 / 20%));
    border: none;
    
    .inner {
        $margin-inner: 10px;

        display: inline-block;
        padding: .75em 2em;
        clip-path: polygon(calc(0% + $margin-inner) 0, calc(100% - $margin-inner) 0, 100% $margin-inner, 100% calc(100% - $margin-inner), calc(100% - $margin-inner) 100%, calc(0% + $margin-inner) 100%, 0 calc(100% - $margin-inner), 0 $margin-inner);
        font-weight: bold;
        line-height: 1.25em;
        color: #fff;
        background-color: var(--color-red);
        transition-duration: 0.4s;
        transition-property: background-color;
    }
    @include hover {
        .inner {
            background-color: var(--color-red-80);
        }
    }

    &._arrow-right {
        .inner {
            display: flex;
            gap: .5em;
            align-items: center;

            &::after {
                display: inline-block;
                width: 1.5em;
                height: 1em;
                content: "";
                background: url('../images/icon-svg/arrow-right.svg') no-repeat center center;
                background-size: contain;
            }
        }
    }

    &._arrow-down {
        .inner {
            display: flex;
            gap: .5em;
            align-items: center;

            &::after {
                display: inline-block;
                width: 1em;
                height: 1.5em;
                content: "";
                background: url('../images/icon-svg/arrow-down.svg') no-repeat center center;
                background-size: contain;
            }
        }
    }

    $btn-colors: (
        'red',
        'orange',
        'blue',
        'green',
        'purple',
        'brown'
    );
}
@each $name, $color in $colors {

    .btn._#{$name} {
        > .inner {
            background-color: var(--color-#{$name});
        }
        @include hover {
            .inner {
                background-color: var(--color-#{$name}-80);
            }
        }
    }


    .btn._white-#{$name} {
        > .inner {
            color: var(--color-#{$name});
            background-color: #fff;
        }
        @include hover {
            .inner {
                background-color: var(--color-#{$name}-20);
            }
        }

        &._arrow-right {
            > .inner {
                display: flex;
                gap: .5em;
                align-items: center;

                &::after {
                    display: inline-block;
                    width: 1em;
                    height: 1.5em;
                    content: "";
                    background: url('../images/icon-svg/arrow-right-#{$name}.svg') no-repeat center center;
                    background-size: contain;
                }
            }
        }

        &._arrow-down {
            > .inner {
                display: flex;
                gap: .5em;
                align-items: center;

                &::after {
                    display: inline-block;
                    width: 1em;
                    height: 1.5em;
                    content: "";
                    background: url('../images/icon-svg/arrow-down-#{$name}.svg') no-repeat center center;
                    background-size: contain;
                }
            }
        }
    }

}


a.btn {
    text-decoration: none;
}





.video-block {
    position: relative;
    width: 200px;
}

.video-video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
    height: auto;
}

.video-canvas {
    position: relative;
    z-index: 100;
    width: 100%;
    height: auto;
}

.video-wrap {
    padding-right: 2rem;
    padding-left: 2rem;
}

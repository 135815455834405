.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: var(--color-font);

    &._white {
        background-color: #fff;
    }
}
@each $name in $icons {
    .icon {
        &._#{$name} {
            @include icon($name);
        }
    }
}

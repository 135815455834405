@import 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap';

html {
    text-size-adjust: 100%;
}

body {
    font-family: 'Noto Sans JP', "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;;
    font-size: 1rem;

    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    color: var(--color-font);
    letter-spacing: 0.05em;
}

@use '../functions/font' as font;

.map-label {
    .title {
        padding: .25em;
        font-size: font.fz(15);
        color: #fff;
        background-color: var(--color-font);
        text-align: center;
        @include mq {
            font-size: font.fz(12);
        }
    }
}

.map-div-icon {
    position: relative;

    .marker {
        width: 100%;
        height: 100%;
        background: url('../images/map/marker.png') no-repeat center center;
        transition-duration: 0.4s;
        transition-property: scale;
        transform-origin: 50% 100%;
        scale: 0.9;
        translate: 0 -53px;
        @include mq {
            translate: 0 -26px;
            background-image: url('../images/map/marker-sp.png');
        }

        .marker-img {
            width: 80px;
            aspect-ratio: 1;
            overflow: hidden;
            border-radius: 100%;
            translate: 13px 13px;
            @include mq {
                width: 42px;
                translate: 5px 4px;
            }
            img {
                object-fit: cover;
                object-position: center center;
                width: 100%;
                height: 100%;
            }
        }
    }

    &.is-active {
        .marker {
            background-image: url('../images/map/marker-active.png');
            @include mq {
                background-image: url('../images/map/marker-active-sp.png');
            }
        }
    }


    .title {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 10em;
        padding: .5em;
        font-weight: bold;
        color: var(--color-font);
        background-color: #fff;
        text-align: center;
        filter: drop-shadow(0 0 4px rgb(0 0 0 / 20%));
        border-radius: .5em;
        opacity: 0;
        transition-duration: 0.2s;
        transition-property: opacity translate;
        translate: -50% -50%;
    }

    &:hover {
        z-index: 2000;

        .marker {
            scale: 1;
        }

        .title {
            opacity: 1;
            translate: -50% 0%;
        }
    }
}



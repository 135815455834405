
.admin-btn {
    display: inline-block;
    padding: .5em 1.25em;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: var(--color-font);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: none;
    border-radius: .25em;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    @each $name, $color in $admin-colors {
        &._#{$name} {
            background-color: $color;
            @if $name == 'info' or $name == 'warning' {
                color: #000;
            } @else {
                color: #fff;
            }
            &:hover {
                background-color: darken($color, 10%);
            }
        }        
    }

}

.admin-control {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    padding: 1em 1em 4em;
}

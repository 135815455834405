$admin-color-primary: #0d6efd;
$admin-color-secondary: #6c757d;
$admin-color-success: #198754;
$admin-color-danger: #dc3545;
$admin-color-warning: #ffc107;
$admin-color-info: #0dcaf0;
$admin-color-light: #f8f9fa;

$admin-colors: (
    'primary': $admin-color-primary,
    'secondary': $admin-color-secondary,
    'success': $admin-color-success,
    'danger': $admin-color-danger,
    'warning': $admin-color-warning,
    'info': $admin-color-info,
    'light': $admin-color-light,
);

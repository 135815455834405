@use '../functions/font' as font;

.btn-top-course {
    display: block;
    max-width: 36em;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: none;
    filter: drop-shadow(0 0 4px rgb(0 0 0 / 20%));
    border: none;

    .inner {
        $margin-inner: 10px;

        box-sizing: border-box;
        display: flex;
        gap: 1em;
        align-items: center;
        height: 100%;
        padding: 1.25em 2em 1.5em;
        clip-path: polygon(calc(0% + $margin-inner) 0, calc(100% - $margin-inner) 0, 100% $margin-inner, 100% calc(100% - $margin-inner), calc(100% - $margin-inner) 100%, calc(0% + $margin-inner) 100%, 0 calc(100% - $margin-inner), 0 $margin-inner);
        font-weight: bold;

        // line-height: 1.25em;
        color: #fff;
        background-color: var(--color-orange);
        transition-property: background-color;
        transition-duration: 0.4s;

        @include mq {
            padding: 1em 1em 1.25em;
            gap: 0.5em;
        }

        .icon-id {
            width: 50px;
            height: 64px;
            margin-right: 1em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            @include mq {
                width: 40px;
                height: 50px;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: .5em;
            align-items: flex-start;
            text-align: left;
        }

        .text-title {
            display: block;
            font-size: font.clamp-fz(16, 28);
        }

        .text-time {
            padding: .4em .8em;
            line-height: 1em;
            color: var(--color-font);
            background-color: #fff;
            font-size: font.clamp-fz(14, 16);
        }

        .btn-icon {

        }
    }
    @include hover {
        .inner {
            background-color: var(--color-orange-80);
        }
    }
}

$course-id: ( 1, 2 );
@each $id in $course-id {
    .btn-top-course {
        &._id-#{$id} {
            .icon-id {
                background-image: url('../images/course/icon/course-icon-#{$id}.png');
            }
        }
    }
}


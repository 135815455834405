@use 'sass:color';

$color-red: #ea5450;
$color-orange: #ed6d1f;
$color-blue: #00a6ba;
$color-purple: #af579d;
$color-green: #00a84d;
$color-brown: #7d6b55;
$color-stone: #E9E5DF;;
$color-font: #111;
$color-font-brown: #5f5140;
$color-link: #007A89;
$color-link-hover: #00a7bc;
$colors: (
    'red': #{$color-red},
    'orange': #{$color-orange},
    'blue': #{$color-blue},
    'purple': #{$color-purple},
    'green': #{$color-green},
    'brown': #{$color-brown},
    'white': #fff
);
$color-require: #ea5450;
$color-error: #ea5450;

:root {
    --color-red: #{$color-red};
    --color-red-80: #{color.mix($color-red, #fff, 80%)};
    --color-red-60: #{color.mix($color-red, #fff, 60%)};
    --color-red-40: #{color.mix($color-red, #fff, 40%)};
    --color-red-30: #{color.mix($color-red, #fff, 30%)};
    --color-red-20: #{color.mix($color-red, #fff, 20%)};
    --color-orange: #{$color-orange};
    --color-orange-80: #{color.mix($color-orange, #fff, 80%)};
    --color-orange-60: #{color.mix($color-orange, #fff, 60%)};
    --color-orange-40: #{color.mix($color-orange, #fff, 40%)};
    --color-orange-30: #{color.mix($color-orange, #fff, 30%)};
    --color-orange-20: #{color.mix($color-orange, #fff, 20%)};
    --color-blue: #{$color-blue};
    --color-blue-80: #{color.mix($color-blue, #fff, 80%)};
    --color-blue-60: #{color.mix($color-blue, #fff, 60%)};
    --color-blue-40: #{color.mix($color-blue, #fff, 40%)};
    --color-blue-30: #{color.mix($color-blue, #fff, 30%)};
    --color-blue-20: #{color.mix($color-blue, #fff, 20%)};
    --color-purple: #{$color-purple};
    --color-purple-80: #{color.mix($color-purple, #fff, 80%)};
    --color-purple-60: #{color.mix($color-purple, #fff, 60%)};
    --color-purple-40: #{color.mix($color-purple, #fff, 40%)};
    --color-purple-30: #{color.mix($color-purple, #fff, 30%)};
    --color-purple-20: #{color.mix($color-purple, #fff, 20%)};
    --color-green: #{$color-green};
    --color-green-80: #{color.mix($color-green, #fff, 80%)};
    --color-green-60: #{color.mix($color-green, #fff, 60%)};
    --color-green-40: #{color.mix($color-green, #fff, 40%)};
    --color-green-30: #{color.mix($color-green, #fff, 30%)};
    --color-green-20: #{color.mix($color-green, #fff, 20%)};
    --color-brown: #{$color-brown};
    --color-brown-80: #{color.mix($color-brown, #fff, 80%)};
    --color-brown-60: #{color.mix($color-brown, #fff, 60%)};
    --color-brown-40: #{color.mix($color-brown, #fff, 40%)};
    --color-brown-30: #{color.mix($color-brown, #fff, 30%)};
    --color-brown-20: #{color.mix($color-brown, #fff, 20%)};
    --color-stone: #{$color-stone};
    --color-white: #fff;
    --color-white-80: #{color.mix(#fff, #999, 80%)};
    --color-font: #{$color-font};
    --color-font-brown: #{$color-font-brown};
    --color-link: #{$color-link};
    --color-link-hover: #{$color-link-hover};
    --color-require: #{$color-require};
    --color-error: #{$color-error};
}

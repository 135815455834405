.to-top {
    position: fixed;
    right: 30px;
    bottom: 50px;
    z-index: 2000;
    display: block;
    width: fit-content;
    pointer-events: none;
    opacity: 0;
    transition-duration: 0.4s;
    transition-property: opacity, translate, scale;
    scale: 0.8;
    translate: 0 40px;

    &.is-absolute {
        position: absolute;
    }

    &.is-active {
        pointer-events: initial;
        opacity: 1;
        translate: 0 0;
    }
    @include hover {
        scale: 1.0;
    }
    @include mq {
        display: none;
    }
}

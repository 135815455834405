@use '../functions/font' as font;

.page-top {
    .site-main {
        overflow: hidden;
    }
}

.top-hero {
    width: 100%;
    // height: calc(100svh - 100px);
    height: 100svh;
    position: relative;

    // background: url('../images/top/hero-pc.jpg') no-repeat center center;
    // background-size: cover;
    // @include mq {
    //     background-image: url('../images/top/hero-sp.jpg');
    // }
    .bg {
        width: 100%;
        height: 100%;
        picture, img {
            display: block;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    .text-top {
        box-sizing: border-box;
        padding: 200px 60px 30px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        text-align: right;
        display: block;
        @include mq {
            padding: 130px 20px 20px;
            width: 100%;
            text-align: center;
        }
    }
    .text-bottom {
        box-sizing: border-box;
        width: 100%;
        padding: 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        display: block;
    }

}

.top-news {
    position: relative;
    padding: 3rem 2rem;
    text-align: center;
    @include mq {
        padding: 2rem 2rem;
    }
}

.top-news-title {
    margin: 0 0 1.75em;
    font-size: font.fz(26);
    font-weight: bold;
    color: var(--color-red);
    @include mq {
        font-size: font.fz(22);
        margin-bottom: 1.25em;
    }
}

.top-news-list {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    max-width: 736px;
    padding: 0;
    margin: 0 auto 60px;
    text-align: left;
    list-style: none;
    @include mq {
        max-width: 100%;
        margin-bottom: 2rem;
    }

    > li {
        position: relative;
        filter: drop-shadow(0 0 4px rgb(0 0 0 / 20%));

        > a {
            $margin-inner: 10px;

            position: relative;
            display: flex;
            gap: 4em;
            padding: 1.25em 4em 1.25em 2em;
            clip-path: polygon(calc(0% + $margin-inner) 0, calc(100% - $margin-inner) 0, 100% $margin-inner, 100% calc(100% - $margin-inner), calc(100% - $margin-inner) 100%, calc(0% + $margin-inner) 100%, 0 calc(100% - $margin-inner), 0 $margin-inner);
            color: var(--color-font);
            text-decoration: none;
            background-color: #fff;
            @include mq {
                flex-direction: column;
                gap: .25em;
            }

            .body {
                font-weight: bold;
                color: var(--color-red);
            }

            .icon-arrow {
                position: absolute;
                top: 50%;
                right: 1em;
                translate: 0 -50%;
            }
        }

        .new {
            position: absolute;
            top: -.6em;
            left: 2em;
            padding: .25em .5em;
            line-height: 1em;
            color: #fff;
            background-color: var(--color-red);
        }
    }
}

.top-section-title {
    display: flex;
    gap: 0.25em;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: .75em auto .25em;
    font-size: font.clamp-fz(26, 50);
    letter-spacing: 0.1em;
    max-width: 90%;
    @include mq {
        max-width: 100%;
    }

    strong {
        padding: .5em;
        font-size: font.fz(18);
        line-height: 1em;
        border: 2px solid var(--color-font);
    }
    @include mq {
        flex-direction: column;
    }
}

.lang-en {
    .top-section-title {
        font-size: font.clamp-fz(30, 50);
        letter-spacing: 0.05em;
        line-height: 1.25em;
        margin-bottom: 1em;
    }
}

.top-section-sub-title {
    width: fit-content;
    margin: .5em auto 0;
    font-size: font.clamp-fz(20, 32);
    font-weight: bold;
    line-height: 1.75em;
    letter-spacing: 0.15em;
    max-width: 90%;
    @include mq {
        min-width: 100%;
    }

    b {
        font-size: 150%;
    }
}

.top-section-desc {
    line-height: 1.75em;
    @include mq {
        font-size: font.fz(15);
    }
}

.lang-en {
    .top-section-sub-title {
        font-size: font.clamp-fz(22, 32);
        line-height: 1.5em;
        letter-spacing: 0.05em;
    }
}


.top-section {
    position: relative;
    padding: 10rem 1rem 8rem;
    // overflow: hidden;
    @include mq {
        padding: 4rem 1rem 2rem;
    }
    p {
        font-weight: bold;
    }

    .top-section-dino {
        position: absolute;
        top: 100px;

        &._left {
            left: 0;
        }

        &._right {
            right: 0;
        }
        @include mq {
            display: none;
        }
    }

    .top-section-icon {
        transition-duration: 0.4s;
        transition-property: translate, opacity;
        translate: 0 20px;
        opacity: 0;
        @include mq {
            width: 60px;
            height: auto;
            margin-right: auto;
            margin-left: auto;
        }
    }

    .top-section-title {
        will-change: transform;
        transition-delay: 0.2s;
        transition-duration: 0.4s;
        transition-property: translate, opacity;
        translate: 0 20px;
        opacity: 0;
    }

    .top-section-sub-title {
        will-change: transform;
        transition-delay: 0.4s;
        transition-duration: 0.4s;
        transition-property: translate, opacity;
        translate: 0 20px;
        opacity: 0;
    }

    .top-section-desc {
        width: fit-content;
        margin-right: auto;
        margin-left: auto;
        will-change: transform;
        transition-delay: 0.6s;
        transition-duration: 0.4s;
        transition-property: translate, opacity;
        translate: 0 20px;
        opacity: 0;
    }

    &.is-active {
        .top-section-icon {
            opacity: 1;
            translate: 0 0;
        }

        .top-section-title {
            opacity: 1;
            translate: 0 0;
        }

        .top-section-sub-title {
            opacity: 1;
            translate: 0 0;
        }

        .top-section-desc {
            opacity: 1;
            translate: 0 0;
        }
    }

}

.top-about-inner {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
    max-width: $width-contents;
    padding: 64px 0;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;

    b {
        font-size: 150%;
        font-weight: bold;
    }
    @include mq {
        gap: 1rem;
    }
}




.top-about {
    padding: 0 2rem 10rem;
    @include mq {
        padding-bottom: 2rem;
    }

    .logo-lead {
        display: flex;
        gap: 2rem;
        align-items: center;
        will-change: transform;
        transition-duration: 0.4s;
        transition-property: translate, opacity;
        translate: 0 20px;
        opacity: 0;
        .logo {
            max-width: 250px;
            height: auto;
        }

        .lead {
            margin: 0;
            font-size: font.clamp-fz(22, 50);
            font-weight: bold;
            line-height: 1.5em;
        }
        @include mq(850px) {
            gap: 1rem; 
            .logo {
                width: 30%;
            }
            .lead {
                // width: calc(75% - 1rem);
            }
            // flex-direction: column;
        }
    }

    .text {
        font-size: font.clamp-fz(15, 19);
        text-align: center;
        will-change: transform;
        transition-duration: 0.4s;
        transition-delay: 0.4s;
        transition-property: translate, opacity;
        translate: 0 20px;
        opacity: 0;
        p {
            line-height: 2.5em;
        }

    }

    &.is-active {
        .logo-lead {
            opacity: 1;
            translate: 0 0;
        }

        .text {
            opacity: 1;
            translate: 0 0;
        }
    }

}



.top-dino {
    position: relative;
    padding-top: 4rem;
    color: #fff;
    text-align: center;
    background-color: var(--color-blue-80);

    .top-section-title {
        background-color: var(--color-blue-80);
        strong {
            border-color: #fff;
        }
    }
    .top-section-sub-title {
        background-color: var(--color-blue-80);
    }
    .top-section-desc {
        background-color: var(--color-blue-80);
    }

    .top-section-dino {
        &._right {
            top: 0;
        }

        &._left {
            top: 180px;
        }
    }

    &::before,
    &::after {
        position: absolute;
        left: 0;
        z-index: 100;
        display: block;
        width: 100%;
        height: 90px;
        content: "";
        background-repeat: repeat-x;
        background-position: 0 center;
        background-size: auto 100%;
        @include mq {
            height: 40px;
        }
    }

    &::before {
        top: -90px;
        background-image: url('../images/patterns/blue-top.png');
        @include mq {
            top: -40px;
        }
    }

    &::after {
        bottom: -90px;
        background-image: url('../images/patterns/blue-bottom.png');
        @include mq {
            bottom: -40px;
        }
    }


}

.top-dino-map {
    width: 90%;
    height: 600px;
    margin: 2rem auto 3rem;
    @include mq {
        width: calc(100% + 2rem);
        height: 50svh;
        margin-left: -1rem;
        margin-right: -1rem;
    }
}

.slick-controller {
    display: flex;
    gap: 1em;
    align-items: center;
    justify-content: center;
    padding: 1em 0 2em;
    > * {
        flex-shrink: 0;
    }
    .slick-navigation {
        font-size: font.fz(20);
        width: auto;
        font-weight: bold;   
    }
}

.top-spot {
    position: relative;
    text-align: center;
    background-color: var(--color-purple-60);
    padding-right: 0;
    padding-left: 0;
    @include mq {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .top-section-dino {
        &._right {
            top: 320px;
        }
    }

    &::after {
        position: absolute;
        bottom: -90px;
        left: 0;
        z-index: 100;
        display: block;
        width: 100%;
        height: 90px;
        content: "";
        background-image: url('../images/patterns/purple-bottom.png');
        background-repeat: repeat-x;
        background-position: 0 center;
        background-size: auto 100%;
        @include mq {
            height: 40px;
            bottom: -40px;
        }
    }

    .slick {
        margin-top: 3rem;
        margin-left: -1rem;
        margin-right: -1rem;
    }
    .slick-slide {
        $offset: 32px;

        position: relative;
        display: block;
        width: 352px;
        // height: 264px;
        aspect-ratio: 352 / 264;
        margin-right: 32px;
        clip-path: polygon(calc(0% + $offset) 0, calc(100% - $offset) 0, 100% $offset, 100% calc(100% - $offset), calc(100% - $offset) 100%, calc(0% + $offset) 100%, 0 calc(100% - $offset), 0 $offset);

        @include mq(600px) {
            width: 85vw;
        }

        img {
            width: 100%;
            // aspect-ratio: 352 / 264;
            object-fit: contain;
        }

        .title {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            box-sizing: border-box;
            padding: .5em 32px;
            margin: 0;
            color: var(--color-font);
            text-align: center;
            background-color: #fff;
        }
    }

    .slick-wrapper {
        height: auto;
    }
    .slick-navigation {
        &__current {
            color: #640a51;
        }
    }

    .top-section-title {
        background-color: var(--color-purple-60);
    }
    .top-section-sub-title {
        background-color: var(--color-purple-60);
    }
    .top-section-desc {
        background-color: var(--color-purple-60);
    }
}



.top-spot {
    .slick-controller {
        .top-slider-navigation {
            .swiper-pagination-current {
                color: var(--color-purple);
            }
        }
    }
}

.slick-pagination-prev,
.slick-pagination-next {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5em;
    line-height: 1em;
    background-color: var(--color-font);
    border: none;
    border-radius: 100%;
    font-size: 1rem;
    cursor: pointer;
    @include hover {
        background-color: #333;
    }
    .icon {
        background-color: #fff;
        height: 1rem;
        width: 1rem;
    }
}



._event {
    .top-slider-navigation {
        .swiper-pagination-current {
            color: #fff;
        }
    }
}

.top-event {
    position: relative;
    color: #fff;
    text-align: center;
    background-color: var(--color-brown);
    padding-right: 0;
    padding-left: 0;
    @include mq {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .top-section-dino {
        &._left {
            top: 140px;
        }

        &._right {
            top: 380px;
        }
    }

    .slick {
        margin-top: 4rem;
    }

    .slick-slide {
        display: block;
        width: 70vw;
        max-width: 700px;
        margin-right: 64px;
        aspect-ratio: 864 / 648;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        @include mq {
            width: 85vw;
        }
    }

    &::after {
        position: absolute;
        bottom: -90px;
        left: 0;
        z-index: 100;
        display: block;
        width: 100%;
        height: 90px;
        content: "";
        background-image: url('../images/patterns/brown-bottom.png');
        background-repeat: repeat-x;
        background-position: 0 center;
        background-size: auto 100%;
        @include mq {
            height: 40px;
            bottom: -40px;
        }
    }

    .top-section-title {
        background-color: var(--color-brown);
    }
    .top-section-sub-title {
        background-color: var(--color-brown);
    }
    .top-section-desc {
        background-color: var(--color-brown);
    }

}


.top-course {
    position: relative;
    text-align: center;
    background-color: var(--color-orange-60);

    &::after {
        position: absolute;
        bottom: -90px;
        left: 0;
        z-index: 50;
        display: block;
        width: 100%;
        height: 90px;
        content: "";
        background-image: url('../images/patterns/orange-bottom.png');
        background-repeat: repeat-x;
        background-position: 0 center;
        background-size: auto 100%;
        @include mq {
            bottom: -40px;
            height: 40px;
        }
    }

    .top-section-title {
        background-color: var(--color-orange-60);
    }
    .top-section-sub-title {
        background-color: var(--color-orange-60);
    }
    .top-section-desc {
        background-color: var(--color-orange-60);
    }
}

.wrap-btn-top-course {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
    margin-top: 2rem;
}


.top-fukui {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
    background: url('../images/bg/bg-color.png') repeat 0 0;
    @include mq {
        padding-top: 40px;
        padding-bottom: 30px;
    }
}

.top-fukui-top {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 3rem 1.5rem;
    @include mq {
        padding-bottom: 1rem;
        flex-direction: column;
    }

    .img {
        @include mq {
            width: 200px;
            max-width: 100%;
        }
    }
    .text {
        text-align: left;
    }

    .title {
        width: fit-content;
        padding: .5em;
        margin: 0 0 .5em;

        // font-size: font.fz(24);
        font-size: font.clamp-fz(20, 24);
        line-height: 1.5em;
        color: #fff;
        background-color: var(--color-purple);
    }

    .sub-title {
        margin: 0;
        font-size: font.clamp-fz(28, 40);
        font-weight: bold;

        // font-size: font.fz(40);
        line-height: 1.5em;
        color: var(--color-purple);
    }

}

.top-fukui__links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
    margin: 0;
    list-style: none;
    @include mq {
        // grid-template-rows: 1fr 1fr 1fr;
        // grid-template-columns: 1fr;
    }

    li {
        a {
            text-decoration: none;
        }
    }

    .link {
        position: relative;
        display: block;
        aspect-ratio: 1;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            transition-duration: 0.4s;
            transition-property: scale;
            object-fit: cover;
        }

        .text {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 1em;
            font-size: font.clamp-fz(18, 40);
            font-weight: bold;
            color: #fff;
            background-color: rgba(#000, 0.3);
            transition-duration: 0.4s;
            transition-property: background-color;
            @include mq {

            }
        }
    }

    a.link {
        @include hover {
            .text {
                background-color: rgba(#000, 0);
            }

            img {
                scale: 1.1;
            }
        }
    }
}

.lang-en {
    .top-fukui__links .link .text {
        font-size: font.fz(14);
        line-height: 1.25em;
    }
}

.top-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 32px;
    @include mq {
        padding: 40px 1rem;
    }
    .title {
        margin: 0;
        font-size: font.fz(28);
    }

    .desc {
        margin-top: 0;
        margin-bottom: 3rem;
    }

    .banner-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 32px;
        @include mq {
            grid-template-columns: auto;
        }
    }

    .banner {
        display: block;
        filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 15%));
        transition-duration: .4s;
        transition-property: translate;
        @include hover {
            translate: -6px -6px;
        }
    }
}

.lang-en {
    .top-banner {
        .title {
            margin-bottom: 1em;
        }
    }
}

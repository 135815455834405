@use '../functions/font' as font;

.page-header {
    position: relative;
    padding: 5rem 32px calc(90px + 1.5rem);
    text-align: center;
    background: url('../images/bg/bg-brown-light.png') repeat 0 0;

    .title {
        width: fit-content;
        min-width: 10em;
        padding: .75em 1em;
        margin: 0 auto;
        font-size: font.fz(34);
        line-height: 1.25em;
        background: url("../images/title/bg-sub-title.svg") no-repeat center center;
    }

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 90px;
        content: "";
        background: url('../images/patterns/white-top.png') repeat-x 0 0;
    }
}

.page-contents {
    max-width: $width-contents-s;
    padding: 2rem 2rem 4rem;
    margin-right: auto;
    margin-left: auto;
}

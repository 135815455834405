@use '../functions/font' as font;

.news-page-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 90px 2rem 120px;
    background: url('../images/bg/bg-red-light.png') repeat 0 0;
    @include mq {
        padding: 45px 1.5rem 70px;
    }
}

.page-header__title {
    width: fit-content;
    min-width: 10em;
    padding: 1em;
    margin: 0;
    text-align: center;
    background: url('../images/title/bg-sub-title.svg') no-repeat center center;
    background-size: 100%;
    font-size: font.clamp-fz(20, 36);
}

.news-view {
    position: relative;
    padding-top: 32px;
    padding-bottom: 64px;
    padding-right: 2rem;
    padding-left: 2rem;

    &::before {
        position: absolute;
        top: -90px;
        left: 0;
        display: block;
        width: 100%;
        height: 90px;
        content: "";
        background-image: url('../images/patterns/white-top.png');
        background-repeat: repeat-x;
        background-position: 0 center;
        background-size: auto 100%;
        @include mq {
            height: 45px;
            top: -45px;
        }
    }
}

.news-view__header {
    max-width: $width-contents-s;
    margin: 0 auto 3rem;
    &__title {
        font-size: font.clamp-fz(20, 32);
        margin: 0 0 .25em;
    }
    &__date {

    }
    .new {
        width: fit-content;
        padding: .25em .5em;
        line-height: 1em;
        color: #fff;
        background-color: var(--color-red);
    }
}

.news-view__contents {
    max-width: $width-contents-s;
    margin: 0 auto 10em;
}

.news-view__btn-wrapper {
    padding-bottom: 10em;
    text-align: center;
}

$break-point: 759px!default;
@mixin mq($bp: $break-point) {
    @media screen and ( max-width: $bp ) {
        @content;
    }
}
@mixin mq-max($bp: $break-point) {
    @media screen and ( max-width: $bp ) {
        @content;
    }
}
@mixin mq-min($bp: $break-point) {
    @media screen and ( min-width: $bp ) {
        @content;
    }
}

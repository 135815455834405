$color-mouse-stalker: (
    'red',
    'orange',
    'blue',
    'purple',
    'green',
    'brown',
);

.mouse-stalker {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    pointer-events: none;

    // background-image: url('../images/mouse-stalker/mouse-stalker-red.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    opacity: 0;
    transition-timing-function: cubic-bezier(.71,0,.39,1);

    // mix-blend-mode: overlay;
    transition-duration: 0.2s;
    transition-property: opacity, scale, background-image;
    scale: 0;
    @each $color in $color-mouse-stalker {
        &._color-#{$color} {
            background-image: url('../images/mouse-stalker/mouse-stalker-#{$color}.svg');
        }
    }

    &.is-active {
        opacity: 0.9;
        scale: 2;
    }
}

@use '../functions/font' as font;
@mixin tinymce {
    h2 {
        padding-left: .5em;
        margin: 2em 0 .5em;
        font-size: font.fz(30);
        border-left: .25em solid var(--color-brown);
    }

    h3 {
        margin: 2em 0 .5em;
        font-size: font.fz(26);
        color: var(--color-font-brown);
    }

    h4 {
        margin: 2em 0 .5em;
        font-size: font.fz(22);
        color: var(--color-font-brown);
    }

    h5 {
        margin: 2em 0 .5em;
        font-size: font.fz(18);
        color: var(--color-font-brown);
    }

    h6 {
        margin: 2em 0 .5em;
        font-size: font.fz(16);
        color: var(--color-font-brown);
    }

    p {
        margin: 0 0 2em;
        line-height: 2em;
    }

    ul {
        padding-left: 0;
        margin: 0 0 2em;
        list-style: none;

        li {
            position: relative;
            padding-left: 1.5em;
            margin-bottom: 1em;

            &::before {
                position: absolute;
                top: .4em;
                left: 0;
                display: inline-block;
                width: 1em;
                height: 1em;
                content: "";
                background-color: var(--color-brown);
                border-radius: 1em;
            }
        }
    }

    a {
        font-weight: bold;
        color: var(--color-link);
        text-decoration: none;

        &:hover {
            color: var(--color-link-hover);
        }
    }

    table {
        margin: 0 0 2em;
        border: none;
        border-width: 1px;

        th {
            padding: 1em;
            text-align: left;
            background-color: var(--color-brown-20);
            border-top: 1px solid var(--color-brown);
            border-right: none;
            border-bottom: 1px solid var(--color-brown);
            border-left: none;

            &:not(:first-child) {
                border-left: 1px solid var(--color-brown-40);
            }
        }

        td {
            padding: 1em;
            border-top: 1px solid var(--color-brown);
            border-right: none;
            border-bottom: 1px solid var(--color-brown);
            border-left: none;

            &:not(:first-child) {
                border-left: 1px solid var(--color-brown-40);
            }
        }
    }

    .sp-none {
        @include mq {
            display: none;
        }
    }
    .pc-none {
        display: none;
        @include mq {
            display: inline-block;
        }
    }

    .custom-div {
        padding: 1em;
        background-color: var(--color-brown-20);
        line-height: 2em;
        &::before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background: url('../images/pin.png') no-repeat center center;
        }
    }

}

@use '../functions/font' as font;
@mixin tinymce-course {
    .course-contents {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(auto, calc($width-contents / 2)) minmax(auto, calc($width-contents / 2)) minmax(0, 1fr);
        gap: 32px;

        @include mq {
            display: flex;
            flex-direction: column-reverse;
        }

    }

    .course-contents-list {
        display: flex;
        flex-direction: column;
        grid-column: 2 / 3;
        gap: 62px;
        padding-left: 0;
        margin: 0 0 5rem;
        list-style: none;

        li {
            position: relative;

            &::after {
                display: block;
                width: 100%;
                height: 100px;
                margin-top: 36px;
                content: "";
                background: url('../images/icon-svg/arrow-down-orange.svg') no-repeat center center;
                background-size: contain;
                @include mq {
                    height: 50px;
                }
            }
            &.not-arrow {
                &::after {
                    display: none;
                }
            }
            @include mq {
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }

        .img {
            $offset: 20px;

            width: fit-content;
            height: 300px;
            margin-left: 6rem;
            clip-path: polygon(calc(0% + $offset) 0, 100% 0, 100% calc(100% - $offset), calc(100% - $offset) 100%, 0 100%, 0 $offset);

            img {
                width: 100%;
                height: 100%;
                object-position: 0 0;
                object-fit: cover;
            }

            @include mq {
                margin-left: 0;
            }
        }
        .text {
            padding-left: 6rem;
            position: relative;
            @include mq {
                padding-left: 0;
                margin-top: 1rem;
            }
        }

        .text-number {
            display: block;
            width: 5rem;
            font-size: 4rem;
            line-height: 1em;
            text-align: right;
            padding-right: 1rem;
            position: absolute;
            top: 0;
            left: 0;
            @include mq {
                font-size: 2rem;
                width: 100%;
                position: relative;
                top: initial;
                left: initial;
                text-align: left;
            }
        }

        %number {
            display: inline-block;
            width: 2rem;
            height: 4rem;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            overflow: hidden;
            text-indent: 100%;
            white-space: nowrap;
            @include mq {
                width: 1rem;
                height: 2rem;
            }
        }
        $numbers: (
            0: 12.16,
            1: 6.65,
            2: 8.49,
            3: 9.17,
            4: 10.32,
            5: 9.17,
            6: 11.01,
            7: 10.32,
            8: 11.47,
            9: 11.24
        );
        @each $number, $percent in $numbers {
            .number-#{$number} {
                @extend %number;
                background-image: url('../images/course/number/number_#{$number}.png');
                width: 0.25rem * $percent;
                @include mq {
                    width: 0.15rem * $percent;
                }
            }
        }

        .text-title {
            color: var(--color-orange);
        }
        .text-desc {
            @include mq {
                padding-left: 0;
            }
        }

        .wrap-btn {
            @include mq {
                padding-left: 0;
                text-align: center;
            }
        }

        .course-list-column {
            margin-top: 100px;
            margin-bottom: 100px;
            margin-left: 6rem;
            font-weight: bold;
            color: #fff;
            position: relative;
            @include mq {
                margin-left: auto;
                margin-right: auto;
            }
            .column-inner {
                padding: 2rem;
                background-color: var(--color-orange-60);
                $offset: 30px;
                clip-path: polygon(calc(0% + $offset) 0, calc(100% - $offset) 0, 100% $offset, 100% calc(100% - $offset), calc(100% - $offset) 100%, calc(0% + $offset) 100%, 0 calc(100% - $offset), 0 $offset);
                @include mq {
                    padding: 1rem;
                }
            }
            .column-title {
                margin: 0 0 .5em;
                text-align: center;
                font-size: font.fz(24);
                @include mq {
                    font-size: font.fz(20);
                }
            }
            .column-img {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                gap: 2rem;
                img {
                    width: calc((100% - 2rem)/2);
                }
            }
            &::before, &::after {
                content: "";
                width: 100%;
                height: 50px;
                display: block;
                position: absolute;
                left: 0;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                @include mq {
                    height: 30px;
                }
            }
            &::before {
                top: -48px;
                background-image: url('../images/course/column-arrow-top.svg');
                @include mq {
                    top: -28px;
                }
            }
            &::after {
                bottom: -48px;
                background-image: url('../images/course/column-arrow-bottom.svg');
                @include mq {
                    bottom: -28px;
                }
            }

        }

    }

    .course-contents-map {
        position: sticky;
        top: 173px;
        grid-column: 3 / 5;
        height: calc(100vh - 173px);
        background-color: #999;
        @include mq {
            height: 40vh;
        }
    }

    .course-footer {
        padding: 4rem 2rem;
        background-color: var(--color-orange-40);
        @include mq {
            padding: 2rem 1rem;
        }

        .course-footer_title {
            width: fit-content;
            padding: 1.5em 2em;
            margin-right: auto;
            margin-left: auto;
            color: var(--color-orange);
            background: url('../images/title/bg-sub-title.svg') no-repeat center center;
            background-size: 100%;
            font-size: font.clamp-fz(20, 28);
            text-align: center;
        }

        .wrap-btn {
            width: fit-content;
            margin-right: auto;
            margin-left: auto;
        }
    }

    .course-footer__contents {
        box-sizing: border-box;
        max-width: $width-contents;
        padding: 4rem;
        margin: 2rem auto 0;
        background-color: #fff;

        @include mq {
            padding: 2rem;
        }

        .contents-title {
            text-align: center;
            font-size: font.clamp-fz(20, 28);
            margin: 0 0 1.5em;
        }

        .img-text {
            display: flex;
            gap: 2rem;

            > * {
                width: 100%;
            }
            @include mq {
                flex-direction: column;
            }
        }

        .text {
            line-height: 2em;
        }
    }

    .course-footer__map {
        max-width: $width-contents;
        height: 480px;
        margin: 0 auto;
        background-color: #999;
    }

}

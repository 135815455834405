@use '../functions/font' as font;

.site-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 2rem;
    align-items: flex-start;
    padding: 2rem;
    color: #fff;
    background-color: #323232;

    a {
        color: #fff;
        text-decoration: none;
    }

}

.site-footer__logo {
    grid-row: 1 / 2;
    grid-column: 1 / 3;

    h2 {
        margin-top: 2em;
        font-size: font.fz(16);
        font-weight: bold;
    }

    p {
        font-size: font.fz(14);
        font-weight: normal;
        line-height: 1.5em;
    }
}

.site-footer__menu {
    display: flex;
    flex-wrap: wrap;
    grid-row: 1 / 2;
    grid-column: 3 / 7;
    gap: 1.5em;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        a {
            display: block;
        }
    }
}

.site-footer__sub-menu {
    display: flex;
    flex-wrap: wrap;
    grid-row: 2 / 3;
    grid-column: 4 / 7;
    gap: 1.5em;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    font-size: font.fz(14);
    list-style: none;
}

.site-footer__copyright {
    grid-row: 2 / 3;
    grid-column: 1 / 4;
    margin: 0;
    font-size: font.fz(14);
    color: #ccc;
    letter-spacing: 0.2em;
}
@include mq {
    .site-footer {
        grid-template-rows: auto auto auto auto;
        grid-template-columns: 1fr;
    }

    .site-footer__logo {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
    }

    .site-footer__menu {
        display: flex;
        flex-direction: column;
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        gap: 0;
        border-top: 1px solid rgb(255 255 255 / 50%);

        li {
            width: 100%;
            border-bottom: 1px solid rgb(255 255 255 / 50%);
        }

        a {
            display: block;
            padding: 1em 0;
        }

    }

    .site-footer__sub-menu {
        display: flex;
        flex-direction: column;
        grid-row: 3 / 4;
        grid-column: 1 / 2;
        gap: 0;

        // border-top: 1px solid rgba(255,255,255, 0.5);
        li {
            width: 100%;

            // border-bottom: 1px solid rgba(255,255,255, 0.5);
        }

        a {
            display: block;
            padding: 1em 0;
        }
    }

    .site-footer__copyright {
        grid-row: 4 / 5;
        grid-column: 1 / 2;
        font-size: font.fz(13);
        letter-spacing: 0.15em;
    }
}

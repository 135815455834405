@for $number from 1 through 10 {
    .u-mt-#{$number} {
        margin-top: #{$number}rem;
    }
    .u-mr-#{$number} {
        margin-right: #{$number}rem;
    }
    .u-mb-#{$number} {
        margin-bottom: #{$number}rem;
    }
    .u-ml-#{$number} {
        margin-left: #{$number}rem;
    }
}

.stamp-video {
    box-sizing: border-box;
    width: 100%;
    aspect-ratio: 16 / 9;
    border: 1px solid #c90000;
}

.stamp-canvas {
    box-sizing: border-box;
    width: 100%;
    aspect-ratio: 16 / 9;
    border: 1px solid #0066c5;
}

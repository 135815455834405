.menu-sp-trigger {
    display: none;
    flex-direction: column;
    gap: 10px;
    width: 50px;
    padding: 10px;
    cursor: pointer;
    background-color: var(--color-blue);
    border: none;

    > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #fff;
    }
    @include mq($width-contents) {
        display: flex;
    }
    
}

.menu-sp-modal {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2100;
    box-sizing: border-box;
    width: 100%;
    padding: 2rem 0;
    pointer-events: none;
    background-color: #fff;
    transition-duration: 0.4s;
    transition-property: opacity, translate;
    translate: 0 -20px;
    opacity: 0;

    &.is-active {
        pointer-events: initial;
        translate: 0 0;
        opacity: 1;
        @include mq-min(calc($width-contents + 1px)) {
            pointer-events: none;
            opacity: 0;        
        }
    }

    .sp-menu {
        border-top: 1px solid #ddd;

        li {
            border-bottom: 1px solid #ddd;
        }

        a {
            display: flex;
            gap: 16px;
            align-items: center;
            padding: .5em 32px;
            font-weight: bold;
            color: var(--color-font);
        }

        .img {
            width: 30%;
            text-align: center;
        }

        .text {
            br {
                display: none;
            }
        }
    }

    .wrap-select-lang {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2em;
    }
}


@import './variables/_admin.scss';
@import './variables/_colors.scss';
@import './variables/_gutter.scss';
@import './variables/_icons.scss';
@import './variables/_width.scss';
@import './functions/_font.scss';
@import './mixin/_hover.scss';
@import './mixin/_icon.scss';
@import './mixin/_media_query.scss';
@import './base/reset';
@import './base/font-ja';
@import './base/typo';
@import './utilities/_display.scss';
@import './utilities/_margin.scss';
@import './elements/_btn-top-course.scss';
@import './elements/_btn.scss';
@import './elements/_container.scss';
@import './elements/_icon-arrow.scss';
@import './elements/_icon.scss';
@import './elements/_menu-sp.scss';
@import './elements/_mouse-stalker.scss';
@import './elements/_to-top.scss';
@import './blocks/_map.scss';
@import './blocks/_menu-header.scss';
@import './blocks/_pagination.scss';
@import './blocks/_site-footer.scss';
@import './blocks/_site-header.scss';
@import './blocks/_site-main.scss';
@import './pages/_404.scss';
@import './pages/_course.scss';
@import './pages/_dino.scss';
@import './pages/_news.scss';
@import './pages/_page.scss';
@import './pages/_stamp.scss';
@import './pages/_top.scss';
@import './pages/_video.scss';
@import './tinymce/_course.scss';
@import './tinymce/_fukui_dinosaur.scss';
@import './tinymce/_index.scss';
@import './libs/slick';
@import './libs/slick-theme';
@import './admin/_button.scss';
@import './admin/_form.scss';

.tinymce {
    @include tinymce;
}

.tinymce-course {
    @include tinymce-course;
}

.tinymce-fukui_dinosaur  {
    overflow: hidden;
    @include tinymce-fukui_dinosaur;
}

.menu-header {
    display: flex;
    gap: 1.5rem;
    padding: 0;
    margin: 0;
    text-align: center;
    list-style: none;

    a {
        font-weight: bold;
        color: var(--color-font);
        .img {
            height: 55px;
            img {
                
            }
        }

        .img, .text {
            pointer-events: none;
        }
    }
}

@use '../functions/font' as font;

.course-container {
    display: flex;
    gap: 32px;
    max-width: $width-contents;
    margin-right: auto;
    margin-left: auto;
}

.course-points {
    width: 100%;
    padding: 0;
    margin: 0;
    color: #fff;
    list-style: none;

    > li {
        margin-bottom: 10rem;
    }

    .img {
        aspect-ratio: 4 / 3;
        clip-path: polygon(10% 0, 0 10%, 0 100%, 90% 100%, 100% 90%, 100% 0);
        background-color: #ccc;
    }

    .title-number {
        font-size: font.fz(80);
        line-height: 1.2em;
    }

    .title-text {
        font-size: font.fz(32);
        line-height: 1.5em;
    }

    .desc {
        line-height: 2em;
    }

    .wrap-btn {
        padding: 1.5em 0 2em;
    }
}


.course-map {
    position: sticky;
    top: 25vh;
    width: 100%;
    height: 60vh;
    background-color: #ddd;
}

.course-header {
    position: relative;
    padding: 2rem 1rem;
    margin-bottom: 120px;
    color: var(--color-orange);
    text-align: center;
    background-color: var(--color-orange-60);
    @include mq {
        margin-bottom: 40px;
    }
    &::after {
        position: absolute;
        bottom: -90px;
        left: 0;
        z-index: 100;
        display: block;
        width: 100%;
        height: 90px;
        content: "";
        background-image: url('../images/patterns/orange-bottom.png');
        background-repeat: repeat-x;
        background-position: 0 center;
        background-size: auto 100%;
        @include mq {
            bottom: -27px;
            height: 28px;

        }
    }

    .sub-title {
        display: flex;
        gap: .5em;
        align-items: center;
        justify-content: center;
        font-size: font.fz(26);
        font-weight: bold;
        color: #fff;

        strong {
            display: inline-block;
            padding: .5em .75em;
            font-size: 70%;
            line-height: 1em;
            color: var(--color-orange);
            background-color: #fff;
            border: 2px solid var(--color-orange);
        }
        @include mq {
            flex-direction: column;
            gap: .25em;
        }
    }

    .title {
        position: relative;
        width: fit-content;
        padding: 1em 2em;
        margin-right: auto;
        margin-left: auto;
        color: var(--color-orange);
        background: url('../images/title/bg-sub-title.svg') no-repeat center center;
        background-size: 100%;
        font-size: font.clamp-fz(26, 38);
        @include mq {
            padding-bottom: 3rem;
        }
        .time {
            position: absolute;
            bottom: 0;
            left: 50%;
            display: block;
            width: fit-content;
            padding: .5em 1em;
            margin-right: auto;
            margin-left: auto;
            font-size: 1rem;
            line-height: 1em;
            color: #fff;
            background-color: var(--color-orange);
            translate: -50% .75rem;

            strong {
                font-size: 150%;
            }
        }
    }
    
}

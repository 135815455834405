@use '../functions/font' as font;

.page-top {
    .site-header {
        position: fixed;
        top: 0;
        left: 0;
    }    
}

.site-header {
    position: sticky;
    top: 0;
    z-index: 2000;
    box-sizing: border-box;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 2rem;
    background-color: #fff;
    @include mq {
        padding: 1rem;
    }

    &::after {
        position: absolute;
        bottom: -43px;
        left: 0;
        display: block;
        width: 100%;
        height: 43px;
        content: "";
        background: url('../images/patterns/header.png') repeat-x 0 0;
        @include mq {
            background-size: 70%;
            height: 20px;
            bottom: -20px;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    a {
        text-decoration: none;
    }
    
    .select-lang {
        position: relative;
        width: fit-content;

        > select {
            padding: .25em 1.5em .25em .5em;
            background-color: #fff;
            border: 1px solid var(--color-font);
        }

        &::after {
            position: absolute;
            top: 50%;
            right: .5em;
            display: inline-block;
            width: 1em;
            height: 1em;
            pointer-events: none;
            content: "";
            background-color: var(--color-font);
            translate: 0 -50%;
            @include icon('caret-down');
        }
    }
    @include mq($width-contents) {
        padding-right: 0;

        .site-header__center {
            display: none;
        }

        .site-header__right {
            display: none;
        }

    }

}


.site-header__right {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    align-items: flex-end;
}


.lang-en {
    .menu-header {
        line-height: 1.25em;
        font-size: font.fz(15);
    }
}
